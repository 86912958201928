.App {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

nav {
  display: flex;
  justify-content: flex-end;
}

.hamburger-menu {
  font-size: 1.5rem;
  background-color: transparent;
  border: none;
}

.text-entry-form {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  width: 100%;
}

label {
  font-weight: bold;
  margin-bottom: 10px;
}

textarea {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
  width: 100%;
}

button[type="submit"] {
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
}

.response {
  margin-top: 20px;
  font-weight: bold;
}
